import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";

import imgFeatured from "../assets/image/snorefreeswiss/illustration-why-snore.jpg";

const WhyDoWeSnore = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='why_snore_title'
          description='why_snore_s1_b'
          image={imgFeatured}
        />
        <div className="inner-banner pt-26 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9 heading-color">
                    {t('why_snore_title')}
                  </h1>
                  {/* ttt </p> */}
                </div>
              </Col>
              <Col xs="10">
                <div className="pt-lg-9 pb-8">
                  <img src={imgFeatured} alt={t('why_snore_title')} className="w-100" />
                </div>
              </Col>

            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('why_snore_s1_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s1_b')} </p>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('why_snore_s2_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s2_b')} </p>
                    <ul className="list-unstyled">
                      {[1, 2, 3, 4, 5,6,7].map((item, index) => (
                      <li className="mt-7 text-color-opacity">
                        <i
                          className="rounded-circle bg-dark d-inline-block me-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        {t('why_snore_s2_b_l'+(index+1))}
                      </li>))
                      }
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('why_snore_s3_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s3_b')} </p>
                    <ul className="list-unstyled">
                      {[1, 2, 3, 4].map((item, index) => (
                        <li className="mt-7 text-color-opacity">
                          <i
                            className="rounded-circle bg-dark d-inline-block me-5"
                            css={`
                            width: 9px;
                            height: 9px;
                          `}
                          ></i>
                          {t('why_snore_s3_b_l' + (index + 1))}
                        </li>))
                      }
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('why_snore_s4_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s4_b')} </p>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s4_b2')} </p>
                    <p className="gr-text-9 text-color-opacity">
                      {t('why_snore_s4_b3')} </p>

                  </div>

                  <Link
                    to="/contact"
                    className="btn btn-blue text-white gr-hover-y px-lg-9"
                  >
                    {t('content2_cta')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                  

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default WhyDoWeSnore;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
