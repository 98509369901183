import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined"

const addWwwPrefix = (domain) => {
  if (!domain.startsWith("www.")) {
    return "www." + domain;
  }
  return domain;
};

export const HeadHelmet = (param) => {
  const { title, description, image } = param;
  const { t } = useTranslation();
  const host = isBrowser && addWwwPrefix(window.location.origin);
  var pathname = isBrowser && window.location.pathname
  if (pathname && pathname.endsWith("/")) {
    pathname = pathname.slice(0, -1);
  }
  const slug = isBrowser && pathname.split('/').slice(1).pop();
  const trailingSlug = (slug && slug.length> 2)?slug+'/':'' // exclude language xx e.g. domain/xx/slug

  return (
    <Helmet>
      <title>{t(title)}</title>
      <meta property="og:title" content={t(title)} />
      <meta property="og:description" content={t(description)} />
      {image && <meta property="og:image" content={image} /> }
      <link rel="canonical" content={host + '/' + trailingSlug} />
      <link rel="alternate" content={host + '/' + trailingSlug} hrefLang='de' />
      <link rel="alternate" content={host + '/en/' + trailingSlug} hrefLang='en' />
      <link rel="alternate" content={host + '/it/' + trailingSlug} hrefLang='it' />
      <link rel="alternate" content={host + '/de/' + trailingSlug} hrefLang='de' />
      <link rel="alternate" content={host + '/fr/' + trailingSlug} hrefLang='fr' />
      <link rel="alternate" content={host + '/en/' + trailingSlug} hrefLang='x-default' />
    </Helmet>);
};

export default HeadHelmet;

